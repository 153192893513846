<template>
  <v-container id="user-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      
        <v-tabs color="#1b5e20">
          <v-tab @click="go()">Purchase Orders</v-tab>

          <v-tab-item>
            <v-card-title>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#1b5e20"
                    class="white--text"
                    elevation="2"
                    v-bind="attrs"
                    v-on="on"
                    >Add Purchase Orders</v-btn
                  >
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Name"
                              required
                              :rules="nameRules"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              v-model="editedItem.code"
                              label="Code"
                              :rules="codeRules"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              v-model="editedItem.platform"
                              label="Platform"
                              required
                              :rules="platformRules"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              v-model="editedItem.invoice"
                              label="Invoice"
                              required
                              :rules="invoiceRules"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              v-model="editedItem.purchase_to"
                              label="Purchase to"
                              type="number"
                              :rules="purchase_toRules"
                            />
                          </v-col>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Purchase Price Information
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.buying_price_idr"
                                    label="Buying price IDR"
                                    type="number"
                                    required
                                    :rules="buying_price_idrRules"
                                    prefix="Rp"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.qty"
                                    label="Qty"
                                    type="number"
                                    required
                                    :rules="qtyRules"
                                    @input="doTotalAmount"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    ref="total_amount"
                                    v-model="editedItem.total_amount"
                                    label="Total amount"
                                    type="number"
                                    required
                                    :rules="total_amountRules"
                                    prefix="Rp"
                                    :disabled="total_amount_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_product_per_invoice"
                                    label="Total product payments per invoice"
                                    type="number"
                                    required
                                    :rules="total_product_per_invoiceRules"
                                    prefix="Rp"
                                    @input="doPayForProducts"
                                  />
                                </v-col>

                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_pay"
                                    label="Total Invoice payments"
                                    type="number"
                                    required
                                    :rules="total_payRules"
                                    prefix="Rp"
                                  />
                                </v-col>
                              </v-expansion-panel-content>
                              <v-col cols="12" sm="6" md="12">
                                <v-text-field
                                  ref="pay_for_products"
                                  v-model="editedItem.pay_for_products"
                                  label="Pay for products"
                                  type="number"
                                  required
                                  :rules="pay_for_productsRules"
                                  prefix="%"
                                  :disabled="pay_for_products_disabled"
                                />
                              </v-col>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Discounts and Payments
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    ref="total_discount"
                                    v-model="editedItem.total_discount"
                                    label="Total discount"
                                    type="number"
                                    required
                                    :rules="total_discountRules"
                                    prefix="Rp"
                                    @input="doTotalDiscount"
                                  />
                                  <!-- :disabled="total_discount_disabled" -->
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    ref="discount_per_product"
                                    v-model="editedItem.discount_per_product"
                                    label="Discount/product"
                                    type="number"
                                    required
                                    :rules="discount_per_productRules"
                                    prefix="Rp"
                                    :disabled="discount_per_product_disabled"
                                  />
                                </v-col>
                              </v-expansion-panel-content>
                              <v-col cols="12" sm="6" md="12">
                                <v-text-field
                                  v-model="editedItem.pay_per_product"
                                  label="Pay/product"
                                  type="number"
                                  required
                                  :rules="pay_per_productRules"
                                  prefix="Rp"
                                  :disabled="pay_per_product_disabled"
                                  ref="pay_per_product"
                                />
                              </v-col>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Shipping Cost (Direct Cost)
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_shipping_fee_idr"
                                    label="Total Shipping Fee IDR"
                                    type="number"
                                    required
                                    :rules="total_shipping_fee_idrRules"
                                    prefix="Rp"
                                    :disabled="total_shipping_fee_idr_disabled"
                                    ref="total_shipping_fee_idr"
                                    @input="doTotalShippingFee"
                                  />
                                </v-col>
                              </v-expansion-panel-content>
                              <v-col cols="12" sm="6" md="12">
                                <v-text-field
                                  v-model="editedItem.shipping_fee_per_product"
                                  label="Shipping Fee/product"
                                  type="number"
                                  required
                                  :rules="shipping_fee_per_productRules"
                                  prefix="Rp"
                                  :disabled="shipping_fee_per_product_disabled"
                                  ref="shipping_fee_per_product"
                                />
                              </v-col>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                The cost of imports (direct cost pos Indonesia)
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_admin_fee"
                                    label="Total admin fee"
                                    type="number"
                                    required
                                    :rules="total_admin_feeRules"
                                    prefix="Rp"
                                    @input="doTotalAdminFeePerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.admin_fee_per_product"
                                    label="Admin fee/product"
                                    type="number"
                                    required
                                    :rules="admin_fee_per_productRules"
                                    prefix="Rp"
                                    ref="admin_fee_per_product"
                                    :disabled="admin_fee_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_import_duty"
                                    label="Total import duty"
                                    type="number"
                                    required
                                    :rules="total_import_dutyRules"
                                    prefix="Rp"
                                    @input="doTotalCustomDutyPerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.custom_duty_per_product"
                                    label="Custom duty/product"
                                    type="number"
                                    required
                                    :rules="custom_duty_per_productRules"
                                    prefix="Rp"
                                    ref="custom_duty_per_product"
                                    :disabled="custom_duty_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_ppn_import"
                                    label="Total ppn import"
                                    type="number"
                                    required
                                    :rules="total_ppn_importRules"
                                    prefix="Rp"
                                    @input="doTotalPpnImportPerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.ppn_import_per_product"
                                    label="Ppn import/product"
                                    type="number"
                                    required
                                    :rules="ppn_import_per_productRules"
                                    prefix="Rp"
                                    ref="ppn_import_per_product"
                                    :disabled="ppn_import_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_disbursement_fee"
                                    label="Total disbursement fee"
                                    type="number"
                                    required
                                    :rules="total_disbursement_feeRules"
                                    prefix="Rp"
                                    @input="doTotalDisbursementPerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.disbursement_per_product"
                                    label="Disbursement/product"
                                    type="number"
                                    required
                                    :rules="disbursement_per_productRules"
                                    prefix="Rp"
                                    ref="disbursement_per_product"
                                    :disabled="disbursement_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_duty_fee"
                                    label="Total duty fee"
                                    type="number"
                                    required
                                    :rules="total_duty_feeRules"
                                    prefix="Rp"
                                    @input="doTotalDutyFeePerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.duty_fee_per_product"
                                    label="Duty fee/product"
                                    type="number"
                                    required
                                    :rules="duty_fee_per_productRules"
                                    prefix="Rp"
                                    ref="duty_fee_per_product"
                                    :disabled="duty_fee_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_handling_fee"
                                    label="Total handling fee"
                                    type="number"
                                    required
                                    :rules="total_handling_feeRules"
                                    prefix="Rp"
                                    @input="doTotalHandlingFeePerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.handling_fee_per_product"
                                    label="Handling fee/product"
                                    type="number"
                                    required
                                    :rules="handling_fee_per_productRules"
                                    prefix="Rp"
                                    ref="handling_fee_per_product"
                                    :disabled="handling_fee_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_vat"
                                    label="Total VAT"
                                    type="number"
                                    required
                                    :rules="total_vatRules"
                                    prefix="Rp"
                                    @input="doTotalVatPerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.vat_per_product"
                                    label="VAT/product"
                                    type="number"
                                    required
                                    :rules="vat_per_productRules"
                                    prefix="Rp"
                                    ref="vat_per_product"
                                    :disabled="vat_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_vat_10"
                                    label="Total VAT 10% "
                                    type="number"
                                    required
                                    :rules="total_vat_10Rules"
                                    prefix="Rp"
                                    @input="doTotalVat10PerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.vat_10_per_product"
                                    label="VAT 10%/product "
                                    type="number"
                                    required
                                    :rules="vat_10_per_productRules"
                                    prefix="Rp"
                                    ref="vat_10_per_product"
                                    :disabled="vat_10_per_product_disabled"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.other_fee_total"
                                    label="Other fee total"
                                    type="number"
                                    required
                                    :rules="other_fee_totalRules"
                                    prefix="Rp"
                                    @input="doTotalOtherFeePerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.other_fee_per_product"
                                    label="Other fee/product"
                                    type="number"
                                    required
                                    :rules="other_fee_per_productRules"
                                    prefix="Rp"
                                    ref="other_fee_per_product"
                                    :disabled="other_fee_per_product_disabled"
                                  />
                                </v-col>

                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.total_ppn11percent"
                                    label="Total ppn 11%"
                                    type="number"
                                    required
                                    :rules="total_ppn11percentRules"
                                    prefix="Rp"
                                    @input="doTotalPpn11percentPerProduct"
                                  />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                  <v-text-field
                                    v-model="editedItem.ppn11percent_per_product"
                                    label="Ppn 11%/product"
                                    type="number"
                                    required
                                    :rules="ppn11percent_per_productRules"
                                    prefix="Rp"
                                    ref="ppn_import_per_product"
                                    :disabled="ppn11percent_per_product_disabled"
                                  />
                                </v-col>
                              </v-expansion-panel-content>
                              <v-col cols="12" sm="6" md="12">
                                <v-text-field
                                  v-model="editedItem.import_fee_per_product"
                                  label="Import fee/product"
                                  type="number"
                                  required
                                  :rules="import_fee_per_productRules"
                                  prefix="Rp"
                                  ref="import_fee_per_product"
                                  :disabled="import_fee_per_product_disabled"
                                />
                              </v-col>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              v-model="editedItem.purchase_price_per_product"
                              label="Purchase price/product"
                              type="number"
                              required
                              :rules="purchase_price_per_productRules"
                              prefix="Rp"
                              ref="purchase_price_per_product"
                              :disabled="purchase_price_per_product_disabled"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              v-model="editedItem.purchase_price_per_pcs_product"
                              label="Purchase price/pcs product"
                              type="number"
                              required
                              :rules="purchase_price_per_pcs_productRules"
                              prefix="Rp"
                              :disabled="purchase_price_per_pcs_product_disabled"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#1b5e20"
                      class="white--text"
                      elevation="2"
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="#1b5e20"
                      class="white--text"
                      elevation="2"
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <div class="warning text-center">
                    <header class="text-h5">Confirmation</header>
                  </div>
                  <v-card-title class="text-h6"
                    >Are you sure you want to delete this {{ editedItem.name }} ?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#1b5e20"
                      class="white--text"
                      elevation="2"
                      @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="#1b5e20"
                      class="white--text"
                      elevation="2"
                      @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-spacer></v-spacer>
              <v-text-field
                v-model="keyword"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @input="doSearch"
              >
              </v-text-field>
            </v-card-title>

            <v-data-table
              ref="vDataTable"
              :headers="headers"
              :items="purchase_orders"
              hide-default-footer
              class="elevation-0"
            >
              <template #item="{ item, index, headers }">
                <tr style="background-color: red" v-if="item.status == 'n'">
                  <td>
                    {{ index + 1 + (page - 1) * 10 }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.platform }}
                  </td>
                  <td>
                    {{ item.invoice }}
                  </td>

                  <td>
                    {{ item.purchase_price_per_pcs_product }}
                  </td>
                  <td>
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small class="mr-2" @click="synchronousItem(item)">
                      mdi-sync
                    </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                  </td>
                </tr>
                <tr v-else>
                  <td>
                    {{ index + 1 + (page - 1) * 10 }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.platform }}
                  </td>
                  <td>
                    {{ item.invoice }}
                  </td>

                  <td>
                    {{ item.purchase_price_per_pcs_product }}
                  </td>
                  <td>
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-center">
              <v-pagination
                v-model="page"
                @input="go"
                :length="lengthPage"
                :total-visible="5"
                color="#1b5e20"
              ></v-pagination>
            </div>
            <v-chip style="background-color: red" dark>
              Belum terkirim sebagai actual product.
            </v-chip>
          </v-tab-item>
          <v-tab @click="goPlatformMarket()">Setting Selling Price</v-tab>

          <v-tab-item>
            <v-data-table
              ref="vDataTable"
              :headers="headers_platform_market"
              :items="platform_market"
              hide-default-footer
              class="elevation-0"
            >
              <template #item="{ item, index, headers }">
                <tr>
                  <td>
                    {{ index + 1 + (page_platform_market - 1) * 10 }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.markup }}
                  </td>
                </tr></template
              >
            </v-data-table>
            <div class="text-center">
              <v-pagination
                v-model="page_platform_market"
                @input="goPlatformMarket"
                :length="lengthPage_platform_market"
                :total-visible="5"
                color="#1b5e20"
              ></v-pagination>
            </div>
          </v-tab-item>
        </v-tabs>
    </base-material-card>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-row align="center" justify="center">
          <v-overlay color="White" :absolute="absolute" :value="progress">
            <div class="text-center">
              <v-progress-circular :size="70" :width="7" color="blue" indeterminate>
              </v-progress-circular>
            </div>
          </v-overlay>
        </v-row>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    absolute: true,
    progress: false,
    valid: true,
    purchase_orders: [],
    keyword: "",
    page: 0,
    lengthPage: 0,
    headers: [
      { text: "No" },
      { text: "Name"},
      { text: "Platform"},
      { text: "Invoice" },
      { text: "Price product/pcs"},
      { text: "Actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      id: "",
      code: "",
      name: "",
      platform: "",
      invoice: "",
      purchase_to: "",
      buying_price_idr: 0,
      qty: 0,
      total_amount: 0,
      total_product_per_invoice: 0,
      pay_for_products: 0,
      total_discount: 0,
      discount_per_product: 0,
      total_pay: 0,
      pay_per_product: 0,
      total_shipping_fee_idr: 0,
      shipping_fee_per_product: 0,
      total_admin_fee: 0,
      admin_fee_per_product: 0,
      total_import_duty: 0,
      custom_duty_per_product: 0,
      total_ppn_import: 0,
      ppn_import_per_product: 0,
      total_disbursement_fee: 0,
      disbursement_per_product: 0,
      total_duty_fee: 0,
      duty_fee_per_product: 0,
      total_handling_fee: 0,
      handling_fee_per_product: 0,
      total_vat: 0,
      vat_per_product: 0,
      total_vat_10: 0,
      vat_10_per_product: 0,
      other_fee_total: 0,
      other_fee_per_product: 0,
      import_fee_per_product: 0,
      total_ppn11percent: 0,
      ppn11percent_per_product: 0,
      purchase_price_per_product: 0,
      purchase_price_per_pcs_product: 0,
      status: "",
    },
    defaultItem: {
      id: "",
      code: "",
      name: "",
      platform: "",
      invoice: "",
      purchase_to: "",
      buying_price_idr: 0,
      qty: 0,
      total_amount: 0,
      total_product_per_invoice: 0,
      pay_for_products: 0,
      total_discount: 0,
      discount_per_product: 0,
      total_pay: 0,
      pay_per_product: 0,
      total_shipping_fee_idr: 0,
      shipping_fee_per_product: 0,
      total_admin_fee: 0,
      admin_fee_per_product: 0,
      total_import_duty: 0,
      custom_duty_per_product: 0,
      total_ppn_import: 0,
      ppn_import_per_product: 0,
      total_disbursement_fee: 0,
      disbursement_per_product: 0,
      total_duty_fee: 0,
      duty_fee_per_product: 0,
      total_handling_fee: 0,
      handling_fee_per_product: 0,
      total_vat: 0,
      vat_per_product: 0,
      total_vat_10: 0,
      vat_10_per_product: 0,
      other_fee_total: 0,
      other_fee_per_product: 0,
      import_fee_per_product: 0,
      total_ppn11percent: 0,
      ppn11percent_per_product: 0,
      purchase_price_per_product: 0,
      purchase_price_per_pcs_product: 0,
      status: "",
    },
    selectedFile: null,
    progressBar: false,
    nameRules: [(v) => !!v || "Name is required"],
    invoiceRules: [(v) => !!v || "Invoice is required"],
    // purchase_toRules: [(v) => /([0-9])/.test(v) || "Purchase to must be a number"],
    buying_price_idrRules: [
      (v) => /([0-9])/.test(v) || "The IDR purchase price must be a number",
    ],
    qtyRules: [(v) => /([0-9])/.test(v) || "Qty must be a number"],
    total_amountRules: [(v) => /([0-9])/.test(v) || "Total amount must be a number"],
    total_product_per_invoiceRules: [
      (v) => /([0-9])/.test(v) || "Total product payments per invoice must be a number",
    ],
    pay_for_productsRules: [
      (v) => /([0-9])/.test(v) || "Pay for products must be a number",
    ],
    total_discountRules: [(v) => /([0-9])/.test(v) || "Total discount must be a number"],
    discount_per_productRules: [
      (v) => /([0-9])/.test(v) || "Discount/invoice must be a number",
    ],
    total_payRules: [
      (v) => /([0-9])/.test(v) || "Total Invoice payments must be a number",
    ],
    purchase_price_per_productRules: [
      (v) => /([0-9])/.test(v) || "Purchase price/product must be a number",
    ],
    purchase_price_per_pcs_productRules: [
      (v) => /([0-9])/.test(v) || "Purchase price/pcs product must be a number",
    ],
    total_amount_disabled: true,
    pay_for_products_disabled: true,
    total_discount_disabled: true,
    pay_per_product_disabled: true,
    shipping_fee_per_product_disabled: true,
    discount_per_product_disabled: true,
    admin_fee_per_product_disabled: true,
    custom_duty_per_product: true,
    ppn_import_per_product_disabled: true,
    disbursement_per_product_disabled: true,
    duty_fee_per_product_disabled: true,
    handling_fee_per_product_disabled: true,
    vat_per_product_disabled: true,
    vat_10_per_product_disabled: true,
    other_fee_per_product_disabled: true,
    import_fee_per_product_disabled: true,
    purchase_price_per_product_disabled: true,
    purchase_price_per_pcs_product_disabled: true,
    custom_duty_per_product_disabled: true,
    ppn11percent_per_product_disabled: true,

    platform_market: [],
    page_platform_market: 0,
    lengthPage_platform_market: 0,
    headers_platform_market: [{ text: "No" }, { text: "Name" }, { text: "Markup (%)" }],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.go();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/purchase?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.purchase_orders = data;
          // jumlah halaman di dapat dari meta endpoint purchase_orders
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    goPlatformMarket() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/purchase/platform-market?page=" + this.page_platform_market;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.platform_market = data;
          // jumlah halaman di dapat dari meta endpoint purchase_orders
          this.lengthPage_platform_market = meta.last_page;
          this.page_platform_market = meta.current_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/purchase/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.purchase_orders = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    editItem(item) {
      this.editedIndex = this.purchase_orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    synchronousItem(item) {
      this.editedIndex = this.purchase_orders.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.progressBar = true;
      this.progress = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      formData.set("code", this.editedItem.code);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      Object.assign(this.purchase_orders[this.editedIndex], this.editedItem);
      this.axios
        .post("/purchase/synchronous", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: data.status,
          });
          this.purchase_orders.push(this.editedItem);
          this.clear();
        })
        .catch((error) => {
          let { data } = error;
          this.clear();
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.purchase_orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/purchase/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.purchase_orders.splice(this.editedIndex, 1);
          this.closeDelete();
          this.purchase_orders.push(this.editedItem);
          this.go();
          this.progressBar = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.purchase_orders.push(this.editedItem);
          this.go();
          this.progressBar = false;
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        this.progress = true;
        let formData = new FormData();
        formData.set("id", this.editedItem.id);
        formData.set("code", this.editedItem.code);
        formData.set("name", this.editedItem.name);
        formData.set("platform", this.editedItem.platform);
        formData.set("invoice", this.editedItem.invoice);
        formData.set("purchase_to", this.editedItem.purchase_to);
        formData.set("buying_price_idr", this.editedItem.buying_price_idr);
        formData.set("qty", this.editedItem.qty);
        formData.set("total_amount", this.editedItem.total_amount);
        formData.set(
          "total_product_per_invoice",
          this.editedItem.total_product_per_invoice
        );
        formData.set("pay_for_products", this.editedItem.pay_for_products);
        formData.set("total_discount", this.editedItem.total_discount);
        formData.set("discount_per_product", this.editedItem.discount_per_product);
        formData.set("total_pay", this.editedItem.total_pay);
        formData.set("pay_per_product", this.editedItem.pay_per_product);
        formData.set("total_shipping_fee_idr", this.editedItem.total_shipping_fee_idr);
        formData.set(
          "shipping_fee_per_product",
          this.editedItem.shipping_fee_per_product
        );
        formData.set("total_admin_fee", this.editedItem.total_admin_fee);
        formData.set("admin_fee_per_product", this.editedItem.admin_fee_per_product);
        formData.set("total_import_duty", this.editedItem.total_import_duty);
        formData.set("custom_duty_per_product", this.editedItem.custom_duty_per_product);
        formData.set("total_ppn_import", this.editedItem.total_ppn_import);
        formData.set("ppn_import_per_product", this.editedItem.ppn_import_per_product);
        formData.set("total_disbursement_fee", this.editedItem.total_disbursement_fee);
        formData.set(
          "disbursement_per_product",
          this.editedItem.disbursement_per_product
        );
        formData.set("total_duty_fee", this.editedItem.total_duty_fee);
        formData.set("duty_fee_per_product", this.editedItem.duty_fee_per_product);
        formData.set("total_handling_fee", this.editedItem.total_handling_fee);
        formData.set(
          "handling_fee_per_product",
          this.editedItem.handling_fee_per_product
        );
        formData.set("total_vat", this.editedItem.total_vat);
        formData.set("vat_per_product", this.editedItem.vat_per_product);
        formData.set("total_vat_10", this.editedItem.total_vat_10);
        formData.set("vat_10_per_product", this.editedItem.vat_10_per_product);
        formData.set("other_fee_total", this.editedItem.other_fee_total);
        formData.set("other_fee_per_product", this.editedItem.other_fee_per_product);
        formData.set("import_fee_per_product", this.editedItem.import_fee_per_product);
        formData.set("total_ppn11percent", this.editedItem.total_ppn11percent);
        formData.set(
          "ppn11percent_per_product",
          this.editedItem.ppn11percent_per_product
        );
        formData.set(
          "purchase_price_per_product",
          this.editedItem.purchase_price_per_product
        );
        formData.set(
          "purchase_price_per_pcs_product",
          this.editedItem.purchase_price_per_pcs_product
        );

        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        if (this.editedIndex > -1) {
          Object.assign(this.purchase_orders[this.editedIndex], this.editedItem);
          this.axios
            .post("/purchase/update", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.purchase_orders.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.clear();
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
            });
        } else {
          this.axios
            .post("/purchase/store", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.purchase_orders.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        }
      }
    },

    clear() {
      this.go();
      this.close();
      this.progressBar = false;
      this.progress = false;
    },

    doTotalAmount() {
      this.editedItem.total_amount = this.precise_round(
        this.editedItem.qty * this.editedItem.buying_price_idr
      );
      this.$refs.total_amount.update();
    },
    doPayForProducts() {
      this.editedItem.pay_for_products = this.precise_round(
        (this.editedItem.total_amount / this.editedItem.total_product_per_invoice) * 100
      );
      this.$refs.pay_for_products.update();
    },
    doTotalDiscount() {
      // this.editedItem.total_discount = this.precise_round(
      //   this.editedItem.total_product_per_invoice - this.editedItem.total_pay
      // );

      this.editedItem.discount_per_product = this.precise_round(
        this.editedItem.total_discount * (this.editedItem.pay_for_products / 100)
      );

      this.editedItem.pay_per_product = this.precise_round(
        this.editedItem.total_amount - this.editedItem.discount_per_product
      );
      this.doTotalPurchasePricePerProduct();
    },
    doTotalShippingFee() {
      this.editedItem.shipping_fee_per_product = this.precise_round(
        this.editedItem.total_shipping_fee_idr * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalPurchasePricePerProduct();
    },
    // --------------pos-------------
    doTotalAdminFeePerProduct() {
      this.editedItem.admin_fee_per_product = this.precise_round(
        this.editedItem.total_admin_fee * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalCustomDutyPerProduct() {
      this.editedItem.custom_duty_per_product = this.precise_round(
        this.editedItem.total_import_duty * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalPpnImportPerProduct() {
      this.editedItem.ppn_import_per_product = this.precise_round(
        this.editedItem.total_ppn_import * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalPpn11percentPerProduct() {
      this.editedItem.ppn11percent_per_product = this.precise_round(
        this.editedItem.total_ppn11percent * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalDisbursementPerProduct() {
      this.editedItem.disbursement_per_product = this.precise_round(
        this.editedItem.total_disbursement_fee * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalDutyFeePerProduct() {
      this.editedItem.duty_fee_per_product = this.precise_round(
        this.editedItem.total_duty_fee * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalHandlingFeePerProduct() {
      this.editedItem.handling_fee_per_product = this.precise_round(
        this.editedItem.total_handling_fee * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalVatPerProduct() {
      this.editedItem.vat_per_product = this.precise_round(
        this.editedItem.total_vat * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalVat10PerProduct() {
      this.editedItem.vat_10_per_product = this.precise_round(
        this.editedItem.total_vat_10 * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalOtherFeePerProduct() {
      this.editedItem.other_fee_per_product = this.precise_round(
        this.editedItem.other_fee_total * (this.editedItem.pay_for_products / 100)
      );
      this.doTotalImportFeePerProduct();
    },
    doTotalImportFeePerProduct() {
      this.editedItem.import_fee_per_product = this.precise_round(
        this.editedItem.admin_fee_per_product +
          this.editedItem.custom_duty_per_product +
          this.editedItem.ppn_import_per_product +
          this.editedItem.disbursement_per_product +
          this.editedItem.duty_fee_per_product +
          this.editedItem.handling_fee_per_product +
          this.editedItem.vat_per_product +
          this.editedItem.vat_10_per_product +
          this.editedItem.other_fee_per_product +
          this.editedItem.ppn11percent_per_product
      );
      this.doTotalPurchasePricePerProduct();
    },
    doTotalPurchasePricePerProduct() {
      this.editedItem.purchase_price_per_product = this.precise_round(
        this.editedItem.pay_per_product +
          this.editedItem.shipping_fee_per_product +
          this.editedItem.import_fee_per_product
      );
      this.doTotalPurchasePricePerPcsProduct();
    },
    doTotalPurchasePricePerPcsProduct() {
      this.editedItem.purchase_price_per_pcs_product = parseInt(
        this.precise_round(
          this.editedItem.purchase_price_per_product / this.editedItem.qty
        )
      );
    },
    precise_round(num) {
      return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
